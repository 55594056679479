<template>
  <section v-if="dictionary && Object.keys(dictionary).length > 0">
    <portal-target name="BreadCrumbs">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <router-link tag="li" to="/service"
            ><a>Zlecenie serwisowe</a></router-link
          >
          <router-link
            tag="li"
            :to="{
              name: 'ServiceDetails',
              params: { id: this.$route.params.id },
            }"
            class="is-active is-uppercase"
            ><a>{{ name }}</a></router-link
          >
        </ul>
      </nav>
    </portal-target>
    <div class="ym-toptitle-box" v-if="order">
      <fixed-header :threshold="119" fixedClass="ym-toptitle-fixed">
        <div id="toptitle" class="level ym-toptitle">
          <div class="level-left">
            <router-link
              tag="a"
              to="/service/"
              class="button xbtn is-medium is-transparent"
              ><icon name="x"></icon
            ></router-link>
            <b
              v-if="order.priority_id > 0"
              class="badge"
              :style="`margin-right: 8px; color: ${order.priority.colour}`"
            >
              {{ order.priority.name }}
            </b>
            <div>
              <p>Numer zlecenia</p>
              <h2 class="title">{{ name }}</h2>
            </div>
            <!-- <div style="margin-left: 2rem" @click="showStatusModal = true">
              <p>Status</p>
              <h2 class="title" :style="statusColor()" v-if="order.status_id">{{ statusName().toUpperCase() }}</h2>
            </div>           
            <div style="margin-left: 2rem">
                <p class="label-small">Gwarancja</p>
                <h2 class="title" :style="order.is_warranty ? 'color: green' : ''">{{ order.is_warranty ? 'TAK' : 'NIE' }}</h2>
            </div>
            <div v-if="order.warranty_status_id !== 0" style="margin-left: 2rem">
                <p class="label-small">Status gwarancji</p>
                <h2 class="title">{{ warrantyName }}</h2>
            </div> -->
          </div>
          <div class="buttons level-right">
            <button
              @click="confirmTheService"
              v-if="
                order &&
                !order.operator_confirmed &&
                order.operator_id === $store.state.auth.user.id
              "
              class="button is-medium"
              :style="statusColor()"
            >
              <span>Potwierdz zlecenie</span>
            </button>
            <!-- <button v-if="order" @click="orderFv" class="button is-medium is-light"><icon name="file-text"></icon><span>Faktura: <b :style=" order.is_fv ? 'color: green' : 'color: red'">{{ order.is_fv ? 'TAK' : 'NIE' }}</b></span></button> -->
            <!-- <button v-if="order && order.status_id !== 40" @click="orderRemove" class="button is-medium is-light"><icon name="trash"></icon><span>Usuń</span></button> -->
            <button
              v-if="order"
              @click="orderRemove"
              class="button is-medium is-light"
            >
              <icon name="trash"></icon><span>Usuń</span>
            </button>
            <button
              v-if="order && order.status_id !== 10"
              @click="showStatusModal = true"
              class="button is-medium is-light"
            >
              <icon name="edit"></icon><span>Status</span>
            </button>
            <a
              :href="order.pdf_order"
              target="_blank"
              class="button is-medium is-light"
              ><icon name="download"></icon>
              <div>Zlecenie serwisowe</div></a
            >
            <!-- <a v-if="order && order.status_id === 40" :href="order.pdf_protocol" target="_blank" class="button is-medium is-light"><icon name="download"></icon><div>Protokół Serwisowy</div></a>  -->
            <router-link
              tag="a"
              :to="{
                name: 'ServiceEdit',
                params: { id: parseInt(this.$route.params.id) },
              }"
              class="button is-medium is-black"
              ><icon name="edit"></icon><span>Edytuj</span></router-link
            >
            <!-- <a class="button is-medium is-dark"><icon name="check"></icon><span>Wybierz</span></a> -->
          </div>
        </div>
      </fixed-header>
    </div>

    <div class="ym-whitebg pa40" v-if="order">
      <div class="columns" v-if="!order.operator_confirmed">
        <div class="column is-12">
          <div class="ym-infobox">
            Zlecenie serwisowe dodane przez Serwisanta. Do potwierdzenia przez:
            <b>{{ order.operator_name }}</b>
          </div>
        </div>
      </div>
      <div class="columns is-variable is-3">
        <div class="column" v-if="order.number">
          <p class="label-small">Numer zlecenia</p>
          <p class="label">{{ order.number }}</p>
        </div>
        <div class="column" v-if="order.created_at">
          <p class="label-small">Data przyjęcia</p>
          <p class="label">{{ order.created_at }}</p>
        </div>
        <div class="column" v-if="order.priority_id > 0">
          <p class="label-small">
            {{ dictionary["table_order_priority_id_field_label"] }}
          </p>
          <p class="label" :style="`color: ${order.priority.colour}`">
            {{ order.priority.name }}
          </p>
        </div>
        <div
          class="column"
          v-if="order.status_id"
          @click="showStatusModal = true"
        >
          <p class="label-small">Status zlecenia</p>
          <p class="label" :style="statusColor()">
            {{ statusName().toUpperCase() }}
          </p>
        </div>
      </div>
      <div class="columns is-variable is-3">
        <div class="column" v-if="order.operator_name">
          <p class="label-small">
            <span v-if="order.operator_confirmed">Zlecenie przyjął</span
            ><span v-else>Zlecenie do potwierdzenia przez:</span>
          </p>
          <p class="label">{{ order.operator_name }}</p>
        </div>
        <div class="column" v-if="order.technical_name">
          <p class="label-small">
            {{ dictionary["table_order_technical_name_field_label"] }}
          </p>
          <p class="label">{{ order.technical_name }}</p>
        </div>
        <div class="column" v-if="order.repared_at">
          <p class="label-small">Data naprawy</p>
          <p class="label">{{ order.repared_at }}</p>
        </div>
        <div class="column" v-if="order.place_id > 0">
          <p class="label-small">
            {{ dictionary["table_order_place_id_field_label"] }}
          </p>
          <p class="label">{{ order.place.name }}</p>
        </div>
      </div>
      <div class="columns is-variable is-3">
        <div class="column" v-if="order.category_id">
          <p class="label-small">
            {{ dictionary["table_order_category_id_field_label"] }}
          </p>
          <p class="label">{{ orderCategoryName }}</p>
        </div>
      </div>
      <hr />
      <div class="columns is-variable is-3">
        <div
          class="column is-4"
          v-if="order.customer_name || order.customer_firstname"
        >
          <p class="label-small">
            {{ dictionary["table_order_customer_name_field_label"] }}
          </p>
          <p class="label">
            {{
              order.customer_name
                ? order.customer_name
                : `${order.customer_firstname} ${order.customer_lastname}`
            }}
          </p>
        </div>
        <div class="column is-4" v-if="order.customer_address">
          <p class="label-small">Adres</p>
          <p class="label">
            {{ order.customer_address }}<br />{{ order.customer_postcode }}
            {{ order.customer_city }}
          </p>
        </div>
      </div>
      <div class="columns is-variable is-3">
        <div class="column is-4" v-if="order.customer_phone">
          <p class="label-small">Telefon</p>
          <p class="label">{{ order.customer_phone }}</p>
        </div>
        <div class="column is-4" v-if="order.customer_email">
          <p class="label-small">Email</p>
          <p class="label">{{ order.customer_email }}</p>
        </div>
        <div class="column is-4" v-if="order.customer_nip">
          <p class="label-small">NIP</p>
          <p class="label truncate">{{ order.customer_nip }}</p>
        </div>
      </div>
      <hr />
      <div class="columns is-variable is-3">
        <div class="column is-4" v-if="order.device_type">
          <p class="label-small">
            {{ dictionary["table_order_device_type_field_label"] }}
          </p>
          <p class="label">{{ order.device_type }}</p>
        </div>
        <div class="column is-4" v-if="order.device_name">
          <p class="label-small">
            {{ dictionary["table_order_device_name_field_label"] }}
          </p>
          <p class="label">{{ order.device_name }}</p>
        </div>
      </div>
      <div class="columns is-variable is-3">
        <div class="column is-4" v-if="order.device_serial">
          <p class="label-small">
            {{ dictionary["table_order_device_serial_field_label"] }}
          </p>
          <p class="label">{{ order.device_serial }}</p>
        </div>
        <div
          class="column is-4"
          v-if="order.device_address"
          style="display: none"
        >
          <p class="label-small">Miejsce postoju produktu</p>
          <p class="label">{{ order.device_address }}</p>
        </div>
      </div>
      <div class="columns is-variable is-3">
        <div class="column is-4" style="display: none">
          <p class="label-small">
            {{ dictionary["table_order_is_warranty_field_label"] }}
          </p>
          <p class="label">{{ order.is_warranty ? "TAK" : "NIE" }}</p>
        </div>
        <div class="column is-4" v-if="order.warranty_status_id !== 0">
          <p class="label-small">
            {{ dictionary["table_order_warranty_status_field_label"] }}
          </p>
          <p class="label">{{ warrantyName }}</p>
        </div>
      </div>
      <div class="columns is-mobile is-variable is-3">
        <div class="column is-4" style="display: none">
          <p class="label-small">Liczba motogodzin</p>
          <p class="label">{{ order.device_hours }}</p>
        </div>
        <div class="column is-4" v-if="order.service_type_id !== 0">
          <p class="label-small">
            {{ dictionary["table_order_service_type_name_field_label"] }}
          </p>
          <p class="label">{{ order.service_type_name }}</p>
        </div>
      </div>
      <div class="columns is-variable is-3">
        <div class="column is-6" v-if="order.device_service_work">
          <p class="label-small">
            {{ dictionary["table_order_device_service_work_field_label"] }}
          </p>
          <div
            class="label"
            v-html="convertNewLines(order.device_service_work)"
          ></div>
        </div>
      </div>
      <div class="columns is-variable is-3">
        <div class="column is-6" v-if="order.service_time">
          <p class="label-small">
            {{ dictionary["table_order_service_time_field_label"] }}
          </p>
          <p class="label">{{ order.service_time }}</p>
        </div>
      </div>
      <div
        class="columns is-variable is-3"
        v-if="order.orderFiles && order.orderFiles.length > 0"
      >
        <div class="column is-6">
          <p class="label-small">Pliki</p>
          <div style="display: flex; flex-wrap: wrap; gap: 4px">
            <a
              v-for="(file, index) in order.orderFiles"
              :key="index"
              class="tag is-primary"
              target="_blank"
              :href="`${apiStorage.content}/file/${file.name}`"
              >{{ file.label }}</a
            >
          </div>
        </div>
      </div>
      <div
        class="columns is-mobile is-multiline is-variable is-12"
        v-if="files.length > 0"
      >
        <div class="column is-12">
          <p class="label-small">
            {{ dictionary["table_order_image_field_label"] }}
          </p>
        </div>
        <span
          v-for="(file, index) in files"
          :key="index"
          class="column is-12-mobile is-4-tablet"
        >
          <img :src="file.url" style="margin-bottom: 4px" />
          <p v-if="file.content" class="label is-uppercase">
            {{ file.content }}
          </p>
        </span>
      </div>
      <div class="columns is-variable is-3">
        <div class="column is-6" v-if="order.operator_notes">
          <p class="label-small">
            {{ dictionary["table_order_operator_notes_field_label"] }}
          </p>
          <p class="label">{{ order.operator_notes }}</p>
        </div>
      </div>
      <div v-if="order_service.length > 0">
        <hr />
        <div class="columns is-variable is-3">
          <div class="column is-6">
            <b>{{ dictionary["table_order_is_service_field_label"] }}</b
            ><br /><br />
            <div class="ym-table ym-table__info">
              <div
                v-for="service in order_service"
                :key="service.id"
                class="ym-tableItem ym-tableItem__prl"
              >
                <div>{{ service.category }}</div>
                <div>{{ service.name }}</div>
                <div>{{ service.minutes }} min</div>
                <div>{{ service.price }} zł</div>
              </div>
              <div class="ym-tableItem ym-tableItem__prl">
                <div>&nbsp;</div>
                <div><b>Podsumowanie</b></div>
                <div>{{ orderServiceMinutes(order_service) }} min</div>
                <div>{{ orderServiceCash(order_service) }} zł</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-variable is-6" v-if="order.service_price">
        <div class="column is-6">
          <p class="label-small">
            {{ dictionary["table_order_service_price_field_label"] }}
          </p>
          <p class="label">{{ order.service_price }} zł</p>
        </div>
      </div>
      <div class="columns is-variable is-6" v-if="order.cost_price">
        <div class="column is-6">
          <p class="label-small">
            {{ dictionary["table_order_cost_price_field_label"] }}
          </p>
          <p class="label">{{ order.cost_price }} zł</p>
        </div>
      </div>
    </div>
    <div class="ym-whitebg pa40" v-if="order && order.status_id === 40">
      <hr />
      <div class="columns is-mobile is-variable is-6">
        <div class="column is-12" v-if="order.service_fault">
          <p class="label-small">
            {{ dictionary["table_order_service_fault_field_label"] }}
          </p>
          <p class="label">{{ order.service_fault }}</p>
        </div>
      </div>
      <div class="columns is-mobile is-variable is-6">
        <div class="column is-12" v-if="order.service_description">
          <p class="label-small">
            {{ dictionary["table_order_service_description_field_label"] }}
          </p>
          <p class="label">{{ order.service_description }}</p>
        </div>
      </div>
      <div class="columns is-mobile is-variable is-6">
        <div class="column is-12" v-if="order.technical_notes">
          <p class="label-small">
            {{ dictionary["table_order_technical_notes_field_label"] }}
          </p>
          <p class="label">{{ order.technical_notes }}</p>
        </div>
      </div>
      <div class="columns is-mobile is-variable is-6">
        <div class="column is-12" v-if="order.technical_service">
          <p class="label-small">
            {{ dictionary["table_order_technical_service_field_label"] }}
          </p>
          <p class="label">{{ order.technical_service }}</p>
        </div>
      </div>
      <div class="columns is-mobile is-variable is-3">
        <div class="column is-3" style="display: none">
          <p class="label-small">Liczba ha</p>
          <p class="label">{{ order.device_area }}</p>
        </div>
        <div class="column is-3" style="display: none">
          <p class="label-small">Liczba bali</p>
          <p class="label">{{ order.device_bales }}</p>
        </div>
        <div class="column is-3" style="display: none">
          <p class="label-small">Liczba motogodzin</p>
          <p class="label">{{ order.device_hours }}</p>
        </div>
      </div>
      <div class="columns is-mobile is-variable is-3">
        <div class="column is-3" style="display: none">
          <p class="label-small">Płatne</p>
          <p class="label">{{ order.is_paid ? "TAK" : "NIE" }}</p>
        </div>
        <div class="column is-3" style="display: none">
          <p class="label-small">Usługi</p>
          <p class="label">{{ order.is_service ? "TAK" : "NIE" }}</p>
        </div>
        <div class="column is-3" style="display: none">
          <p class="label-small">Handel</p>
          <p class="label">{{ order.is_trade ? "TAK" : "NIE" }}</p>
        </div>
        <div class="column is-3" style="display: none">
          <p class="label-small">Gwarancja</p>
          <p class="label">{{ order.is_warranty ? "TAK" : "NIE" }}</p>
        </div>
      </div>

      <div
        class="columns is-multiline is-mobile is-variable is-3"
        v-if="order_technical.length > 0"
        style="display: none"
      >
        <div class="column is-12">
          <p class="label-small">Serwisanci asystujący</p>
          <div
            class="column is-3"
            v-for="(technical, index) in order_technical"
            :key="technical.id"
          >
            <p class="label">
              {{ index + 1 }}) {{ technical.firstname.toUpperCase() }}
              {{ technical.lastname.toUpperCase() }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="columns is-multiline is-mobile is-variable is-3"
        v-if="order_commute.length > 0"
        style="display: none"
      >
        <div class="column is-12">
          <p class="label-small">Dojazd</p>
          <div class="ym-table ym-table__info">
            <div
              v-for="commute in order_commute"
              :key="commute.id"
              class="ym-tableItem ym-tableItem__prl"
            >
              <div>{{ commute.commuted_at }}</div>
              <div>
                {{ commute.city_from.toUpperCase() }}
                <!-- <small v-if="commute.time_from !== '00:00'">({{ commute.time_from }})</small> -->
              </div>
              <div>
                {{ commute.city_to.toUpperCase() }}
                <!--  <small v-if="commute.time_to !== '00:00'">({{ commute.time_to }})</small> -->
              </div>
              <div>{{ commute.distance }} km</div>
              <div>&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="columns is-multiline is-mobile is-variable is-3"
        v-if="order_work.length > 0"
        style="display: none"
      >
        <div class="column is-12">
          <p class="label-small">Godziny pracy</p>
          <div class="ym-table ym-table__info">
            <div
              v-for="work in order_work"
              :key="work.id"
              class="ym-tableItem ym-tableItem__prl"
            >
              <div>{{ work.worked_at }}</div>
              <div>{{ work.time_from }} - {{ work.time_to }}</div>
              <div>{{ work.minutes }} x{{ work.workers }}</div>
              <div>{{ work.workers_time }}</div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="columns is-mobile is-variable is-3"
        v-if="order.signature"
        style="display: none"
      >
        <div class="column is-4">
          <p class="label-small">Podpis</p>
          <img :src="order.signature_url" />
        </div>
      </div>
    </div>
    <div
      class="ym-whitebg pa40"
      v-if="
        order &&
        order.dedicated_fields &&
        Object.keys(order.dedicated_fields).length > 0
      "
    >
      <hr />
      <div
        v-for="(item, label, index) in order.dedicated_fields"
        class="columns is-mobile is-variable is-6"
        :key="index"
      >
        <div class="column is-12" v-if="item !== ''">
          <p class="label-small">{{ label }}</p>
          <img
            v-if="
              typeof item === 'string' &&
              item.includes('http') &&
              item.match(/[^/]+(jpg|png|gif|jpeg)$/)
            "
            :src="item"
          />
          <a
            v-else-if="typeof item === 'string' && item.includes('http')"
            :href="item"
            class="label"
            >{{ item }}</a
          >
          <p v-else-if="typeof item === 'number'" class="label">
            {{ item === 0 ? "NIE" : "TAK" }}
          </p>
          <p v-else class="label">{{ item }}</p>
        </div>
      </div>
    </div>
    <hr />
    <div class="ym-toptitle-box">
      <div id="toptitle" class="level ym-toptitle">
        <div class="level-left">
          <h2 class="title">
            {{ dictionary["table_order_notes_field_label"] }}
          </h2>
        </div>
      </div>
    </div>
    <OrderNoteCard
      v-for="note in orderNotes"
      :key="note.id"
      :note="note"
      @update="() => getOrderNotes($route.params.id)"
    />
    <ValidationObserver v-slot="{ handleSubmit, errors }" ref="observer">
      <form @submit.prevent="handleSubmit(onOrderNoteSubmit)">
        <div
          :class="
            errors['note_note'] && errors['note_note'].length > 0
              ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
              : 'columns is-mobile is-variable is-3 is-multiline'
          "
        >
          <div
            class="column ym-column is-3-tablet is-12-mobile"
            style="align-items: baseline"
          >
            <div class="ym-label">
              <label for="f_company_full">{{
                dictionary["table_order_notes_add_field_label"]
              }}</label>
              <span
                v-if="errors['note_note'] && errors['note_note'].length > 0"
                class="help is-danger"
                >{{ errors["note_note"][0] }}</span
              >
            </div>
          </div>
          <div class="column ym-column is-9-tablet is-12-mobile">
            <div class="field">
              <label for="note_note">{{
                dictionary["table_order_notes_note_field_label"]
              }}</label>
              <div class="control is-medium">
                <ValidationProvider rules="required" name="note_note">
                  <textarea
                    name="note_note"
                    v-model="order_note.note"
                    class="input is-medium"
                  />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="
            errors['note_service_time'] &&
            errors['note_service_time'].length > 0
              ? 'columns is-mobile is-variable is-3 is-multiline v-field v-error'
              : 'columns is-mobile is-variable is-3 is-multiline'
          "
        >
          <div class="column ym-column is-3-tablet is-12-mobile">
            <div class="ym-label">
              <span
                v-if="
                  errors['note_service_time'] &&
                  errors['note_service_time'].length > 0
                "
                class="help is-danger"
                >{{ errors["note_service_time"][0] }}</span
              >
            </div>
          </div>
          <div class="column ym-column is-9-tablet is-12-mobile">
            <div class="field">
              <label for="note_service_time"
                >{{ dictionary["table_order_notes_service_time_field_label"] }}
                <small>(00:00:00 (hh:mm:ss))</small></label
              >
              <div class="control is-medium">
                <ValidationProvider rules="" name="note_service_time">
                  <input
                    name="note_service_time"
                    placeholder="00:00:00"
                    v-maska="'##:##:##'"
                    type="text"
                    v-model="order_note.service_time"
                    class="input is-medium is-uppercase"
                  />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <div
          style="display: flex; justify-content: flex-end; margin-bottom: 86px"
        >
          <button class="button is-medium is-primary">
            <icon name="check"></icon><span>Dodaj</span>
          </button>
        </div>
      </form>
    </ValidationObserver>
    <custom-modal v-model="showStatusModal" @closed="emptyModalTrigger">
      <template v-slot:title>Zmiana statusu</template>
      <div style="margin-top: 16px; margin-bottom: 16px; padding: 0 16px">
        <b-field label="Status">
          <b-select v-model="modalStatusId" placeholder="Wybierz">
            <option
              v-for="item in status
                .filter((item) => item.is_active)
                .filter((item) => item.id !== 10)
                .sort((a, b) => a.id - b.id)"
              :value="item.id"
              :key="item.id"
              :style="`color: ${item.colour}`"
            >
              {{ item.name }}
            </option>
          </b-select>
        </b-field>
      </div>
      <template v-slot:actions>
        <div class="button is-black is-medium" @click="changeOrderStatus">
          Zmień
        </div>
      </template>
    </custom-modal>
  </section>
</template>

<script>
import FixedHeader from "vue-fixed-header";
import { mapActions, mapGetters } from "vuex";
import YMmodal from "@/components/Modal.vue";
import CustomModal from "@/components/CustomModal";
import OrderNoteCard from "@/components/OrderNoteCard.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "ServiceDetails",
  components: {
    FixedHeader,
    CustomModal,
    ValidationObserver,
    ValidationProvider,
    OrderNoteCard,
  },
  data: function () {
    return {
      orderNotes: [],
      order_note: {
        note: "",
        service_time: "",
      },
      order: {},
      order_commute: [],
      order_service: [],
      order_work: [],
      file: null,
      files: [],
      order_technical: [],
      warrantyStatus: [],
      showStatusModal: false,
      modalStatusId: null,
      order_categories: [],
    };
  },
  mounted() {
    this.getOrder();
    this.getCommuteItems();
    this.getWorkItems();
    this.getOrderImage();
    this.getOrderTechnical();
    this.getWarrantyStatus();
    this.getOrderServiceItems(parseInt(this.$route.params.id));
    this.getOrderNotes(parseInt(this.$route.params.id));
    this.getCategoryItemsCall().then((resp) => (this.order_categories = resp));
  },
  computed: {
    ...mapGetters({
      status: "auth/status",
      dictionary: "dictionary",
      apiStorage: "apiStorage",
    }),
    name: function () {
      return this.order.number;
    },
    warrantyName() {
      const status = this.warrantyStatus.find(
        (item) => item.id === this.order.warranty_status_id
      );
      return status?.name;
    },
    orderCategoryName() {
      const category = this.order_categories.find(
        (item) => item.id === this.order.category_id
      );
      return category?.name;
    },
  },
  methods: {
    ...mapActions({
      getOrderCall: "orders/getOrder",
      removeOrderCall: "orders/removeOrder",
      confirmTheServiceCall: "orders/confirmOrder",
      getCommuteItemsCall: "orders/getCommuteItems",
      getWorkItemsCall: "orders/getWorkItems",
      postOrder: "orders/postOrder",
      getOrderImageCall: "orders/getOrderImage",
      getOrderTechnicalCall: "orders/getOrderTechnical",
      getWarrantyStatusCall: "orders/warrantyStatus",
      getOrderServiceItemsCall: "orders/getOrderServiceItems",
      getOrderNotesCall: "orders/getOrderNotes",
      postOrderNote: "orders/postOrderNote",
      getCategoryItemsCall: "orders/getCategoryItems",
    }),
    convertNewLines(str) {
      return str.replace(/\r\n|\r|\n/g, "<br>");
    },
    getOrderServiceItems(id) {
      this.getOrderServiceItemsCall(id)
        .then((resp) => {
          this.order_service = resp;
        })
        .catch((error) => {
          this.serviceType = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    getOrder() {
      this.getOrderCall({ id: this.$route.params.id })
        .then((resp) => {
          this.order = resp;
          this.modalStatusId = this.order.status_id;
        })
        .catch((error) => {
          this.order = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    orderRemove() {
      this.$buefy.modal.open({
        parent: this,
        component: YMmodal,
        hasModalCard: true,
        props: {
          title: "Czy na pewno chcesz usunąć zlecenie?",
          content: `<span class='is-danger'>Usunięcia zlecenia nie można cofnąć.</span> Zlecenie pozostanie w utworzonych do tej pory protokołach.`,
          cancel: "Anuluj",
          ymHasIcon: true,
          ymIcon: "trash",
          submit: `<span class="mal50i">Usuń</span>`,
          ymConfirm: true,
          ymOnConfirm: () => {
            this.removeOrderCall(this.order)
              .then(({ data }) => {
                document.querySelector(".is-cancel").click();
                this.$router.push({ name: "Service" });
                this.$buefy.toast.open({
                  duration: 5000,
                  message: data.alert[1],
                  type: "is-success",
                  position: "is-bottom",
                });
              })
              .catch(() => {
                document.querySelector(".is-cancel").click();
              });
          },
        },
      });
    },
    confirmTheService() {
      this.$buefy.modal.open({
        parent: this,
        component: YMmodal,
        hasModalCard: true,
        props: {
          title: "Czy na pewno chcesz potwierdzić zlecenie?",
          content: `Zlecenie zostało dodane przez serwisanta.`,
          cancel: "Anuluj",
          ymHasIcon: true,
          ymIcon: "check",
          submit: `<span class="mal50i">Potwierdzam</span>`,
          ymConfirmClass: "button is-medium is-primary",
          ymConfirm: true,
          ymOnConfirm: () => {
            this.confirmTheServiceCall({ order: this.order, id: this.order.id })
              .then(({ data }) => {
                document.querySelector(".is-cancel").click();
                this.getOrder();
                this.$buefy.toast.open({
                  duration: 5000,
                  message: data.alert[1],
                  type: "is-success",
                  position: "is-bottom",
                });
              })
              .catch(() => {
                document.querySelector(".is-cancel").click();
              });
          },
        },
      });
    },
    getCommuteItems() {
      this.getCommuteItemsCall({ id: this.$route.params.id })
        .then((resp) => (this.order_commute = resp))
        .catch((error) => {
          this.order_commute = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    getWorkItems() {
      this.getWorkItemsCall({ id: this.$route.params.id })
        .then((resp) => (this.order_work = resp))
        .catch((error) => {
          this.order_work = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    orderFv() {
      const orderPost = {
        ...this.order,
        is_fv: !this.order.is_fv,
      };

      this.postOrder({ order: orderPost, id: this.$route.params.id })
        .then(() => {
          this.getOrder();
        })
        .catch((error) => {
          this.order = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error ? error.data.error_description : null,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    statusName: function () {
      let status = this.$store.state.auth.status.filter(
        (item) => item.id === this.order.status_id
      );
      if (status) {
        return status[0].name;
      }
    },
    statusColor: function () {
      let status = this.$store.state.auth.status.filter(
        (item) => item.id === this.order.status_id
      );
      if (status.length > 0) {
        return `color: ${status[0].colour}`;
      }
    },
    getOrderImage() {
      this.getOrderImageCall({ id: this.$route.params.id })
        .then((resp) => (this.files = resp))
        .catch((error) => {
          this.order_work = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    getOrderTechnical() {
      this.getOrderTechnicalCall({ id: this.$route.params.id })
        .then((resp) => (this.order_technical = resp))
        .catch((error) => {
          this.order_technical = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error ? error.data.error_description : null,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    getWarrantyStatus() {
      this.getWarrantyStatusCall()
        .then((resp) => (this.warrantyStatus = resp))
        .catch((error) => {
          this.serviceType = {};
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    emptyModalTrigger() {
      this.modalStatusId = null;
    },
    changeOrderStatus() {
      const order = { ...this.order, status_id: this.modalStatusId };
      this.confirmTheServiceCall({ order, id: order.id }).then((data) => {
        this.getOrder();
        this.$buefy.toast.open({
          duration: 5000,
          message: data.alert[1],
          type: "is-success",
          position: "is-bottom",
        });
        this.showStatusModal = false;
      });
    },
    orderServiceMinutes(obj) {
      let sum = 0;
      obj.forEach(function (item) {
        sum += item.minutes;
      });
      return sum;
    },
    orderServiceCash(obj) {
      let sum = 0;
      obj.forEach(function (item) {
        sum += item.price;
      });
      return sum.toFixed(2);
    },
    onOrderNoteSubmit() {
      const payload = {
        ...this.order_note,
        order_id: this.$route.params.id,
      };

      this.postOrderNote(payload)
        .then(() => {
          this.order_note = {
            note: "",
            service_time: "",
          };
          requestAnimationFrame(() => {
            this.$refs.observer.reset();
          });
          this.getOrderNotes(this.$route.params.id);
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    getOrderNotes(id) {
      this.getOrderNotesCall(id)
        .then((resp) => (this.orderNotes = resp))
        .catch((error) => {
          this.orderNotes = [];
          this.$buefy.toast.open({
            duration: 5000,
            message: error.data.error_description,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped></style>
